import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import useCustomer from '@/views/customer/useCustomer'
import customerFilterModal from '@/modal/customerFilterModal'
import util from '@/global/util'
import CustomerModal from '@/modal/customerModal'
import constant from '@/global/const'

export default function useCustomerList(propsConfig) {
  const config = propsConfig || {}
  const {
    customerMenuType,
    customerMenuTypeVI,
    customerListMenuType,
    //
    exportExcel,
    deleteCustomer,
    updateCustomer,
  } = useCustomer()

  const viewAppointment = config.viewMode === 'appointment'

  const customerHeaderFilter = () => reactive({
    created_at: ref(''),
    status: ref(''),
    career: ref(''),
    price: ref(''),
    min_price: ref(''),
    max_price: ref(''),
    currency: ref('million'),
    host_phone: ref(''),
    host_name: ref(''),
    sort: ref(''),
    orderBy: ref(''),
  })

  const filter = customerFilterModal()
  const userData = computed(() => store.state.app.userData)
  const customers = ref([])
  const customersReport = ref({})
  const currentCustomer = ref({})
  const currentCustomerUpdate = CustomerModal()
  const created = ref(false)
  const timeoutSearching = ref(null)
  const exportExcelTime = ref(null)
  const timeFrom = ref(null)
  const timeTo = ref(null)
  const exportExcelCloseDeal = ref('')
  const monthFilter = ref(null)
  const yearFilter = ref(null)
  const tableHeaderFilter = customerHeaderFilter()
  const careers = computed(() => store.state.app.careers)

  const districts = computed(() => store.state.app.districts)
  const wards = computed(() => store.state.app.wards)
  const streets = computed(() => store.state.app.streetsByDistricts)
  const users = computed(() => store.state.app.users)
  const routeName = computed(() => store.state.app.currentRouteName)

  const showImage160 = ref([])

  // Table customers
  const tableColumns = ref([
    {
      label: viewAppointment ? 'Ngày hẹn' : 'Ngày Nhập',
      key: 'created_at',
      tdClass: 'minw-150',
      sortable: true,
    },
    {
      label: 'Tên Khách + Thương Hiệu',
      key: 'host_name',
      tdClass: 'minw-150',
    },
    {
      label: 'Số ĐT',
      key: 'host_phone',
    },
    {
      label: 'Nguồn',
      key: 'source',
      tdClass: 'minw-110',
    },
    {
      label: 'Nhu Cầu',
      key: 'title',
      tdClass: 'py-1 minw-250 max-2line',
    },
    {
      label: 'Ngành Nghề',
      key: 'career',
      tdClass: 'minw-190',
    },
    {
      label: 'GIÁ',
      key: 'price',
      tdClass: 'minw-170',
    },
    {
      label: 'Gặp',
      key: 'meeting_count',
    },
    {
      label: 'Tình Trạng',
      key: 'status',
      tdClass: 'minw-190',
      sortable: true,
    },
    {
      label: 'Ghi Chú',
      key: 'note',
      tdClass: 'minw-250 max-2line',
    },
    {
      label: 'Tương Tác Gần nhất',
      key: 'updated_at',
      formatter: (value, key, item) => util.formatDate(value),
      sortable: true,
    },
    {
      label: 'Đã Chốt',
      key: 'confirm_deal_count',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'sticky-column-right text-right',
      thClass: 'sticky-column-right',
    },
  ])
  const perPage = ref(viewAppointment ? 5 : 50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalCustomers = ref(0)
  const currentCustomerClicked = ref({})
  const currentCustomerHistory = ref([])
  const tableColumnsHistoryUpdate = [
    {
      label: 'Ngày Nhập',
      key: 'created_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Tên',
      key: 'creator.fullname',
    },
    {
      label: 'Số ĐT',
      key: 'data.host_phone1',
    },
    {
      label: 'Nguồn',
      key: 'data.host_name',
    },
    {
      label: 'Nhu Cầu',
      key: 'data.title',
      tdClass: 'py-1 minw-250',
    },
    {
      label: 'Ngành Nghề',
      key: 'data.career',
    },
    {
      label: 'Giá',
      key: 'data.price',
    },
    {
      label: 'Gặp + Thay Đỗi Nhu Cầu',
      key: 'data.meeting_count',
    },
    {
      label: 'Tình Trạng',
      key: 'data.status',
    },
    {
      label: 'Ghi Chú',
      key: 'data.note',
      tdClass: 'minw-250',
    },
    {
      label: 'Tương Tác Gần nhất',
      key: 'updated_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Đã Chốt',
      key: 'data.confirm_deal_count',
    },
  ]
  const getInputPrice = () => {
    if (tableHeaderFilter.currency.toLowerCase() === 'million') {
      return tableHeaderFilter.price * 1000000
    }
    if (tableHeaderFilter.currency.toLowerCase() === 'billion') {
      return tableHeaderFilter.price * 1000000000
    }
    return tableHeaderFilter.price
  }

  const getDateRange = dateString => {
    if (!dateString) {
      return {
        from: '',
        to: '',
      }
    }
    const date = new Date(dateString)
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    return {
      from: startOfDay.toISOString(),
      to: endOfDay.toISOString(),
    }
  }

  const getMeetingDate = () => {
    if (viewAppointment) return new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
    return ''
  }

  const buildQuery = customFilter => http.buildQuery(customFilter || Object.assign(filter, {
    ...tableHeaderFilter,
    // price: '',
    created_at: '',
    // input_price: getInputPrice(),
    ...getDateRange(tableHeaderFilter.created_at || ''),
    page: currentPage.value - 1,
    perpage: perPage.value,
    // product_type: filter.product_type === 'hotel' ? 'hotel' : 'townhouse',
    product_type: '',
    meeting_date: getMeetingDate(),
    // career: 'F&B',
    user_id: customerListMenuType && customerListMenuType.value === 'list' ? filter.user_id : userData.value.id,
  })) || ''

  const fetchCustomersReport = customFilter => {
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('customer/fetchCustomersReport', queryString), res => {
      const list = res.data || []
      const hash = {}
      list.forEach(item => {
        hash[item.status] = item.count
      })
      customersReport.value = [...constant.STATUS_TYPE_CUSTOMER.map(item => ({ status: item.value, count: hash[item.value] }))]
    })
  }

  const fetchCustomers = customFilter => {
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('customer/fetchCustomers', queryString), res => {
      customers.value = res.data?.result || []
      totalCustomers.value = res.data?.total
      fetchCustomersReport()
    })
  }

  const handleSortTable = ({ sortBy, sortDesc }) => {
    tableHeaderFilter.sort = sortBy
    tableHeaderFilter.orderBy = sortDesc ? 'desc' : (sortDesc || 'asc')
  }

  const exportExcelCustomer = () => exportExcel(buildQuery({
    // product_type: 'townhouse',
    product_type: '',
    close_deal_type: exportExcelCloseDeal.value,
    from: new Date(timeFrom.value).toISOString(),
    to: new Date(timeTo.value).toISOString(),
    user_id: customerListMenuType.value === 'list' ? '' : userData.value.id,
  }))

  const clearFilter = initFilter => {
    Object.assign(filter, customerFilterModal(), initFilter)
  }

  const clearHeaderFilter = initFilter => {
    Object.assign(tableHeaderFilter, customerHeaderFilter(), initFilter)
  }

  watch([currentPage, perPage], () => {
    fetchCustomers()
  })

  watch(() => filter.product_type, () => {
    fetchCustomers()
  })

  watch(exportExcelTime, n => {
    const today = new Date()
    if (!n) {
      timeFrom.value = null
      timeTo.value = null
    } else if (n === 'week') {
      timeFrom.value = new Date(today.getTime() - 7 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    } else if (n === 'month') {
      timeFrom.value = new Date(today.getTime() - 30 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    }
  })

  watch(() => filter.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
      .then(res => {
        const arr = res.data?.data || []
        store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({
          district_ids: arr.map(item => item.id)
            .join(','),
        }))
      })
    if (created.value) {
      filter.district_id = ''
      filter.street_id = ''
    }
  })

  watch(() => filter.district_id, n => {
    if (!n || !filter.province_id) return
    store.dispatch('app/fetchWards', {
      province_id: filter.province_id,
      district_id: n,
    })
    if (created.value) {
      filter.ward_id = ''
    }
  })

  watch(tableHeaderFilter, () => {
    clearTimeout(timeoutSearching.value)
    timeoutSearching.value = null

    timeoutSearching.value = setTimeout(() => {
      fetchCustomers()
    }, 500)
  })

  return {
    filter,
    showImage160,
    districts,
    wards,
    streets,
    users,
    customerMenuType,
    customerMenuTypeVI,
    totalCustomers,
    customers,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    userData,
    routeName,
    currentCustomer,
    currentCustomerUpdate,
    customerListMenuType,
    exportExcelCloseDeal,
    created,
    timeTo,
    timeFrom,
    exportExcelTime,
    monthFilter,
    yearFilter,
    customersReport,
    tableHeaderFilter,
    careers,
    currentCustomerClicked,
    currentCustomerHistory,
    tableColumnsHistoryUpdate,
    timeoutSearching,
    buildQuery,
    clearFilter,
    clearHeaderFilter,
    // api
    exportExcelCustomer,
    fetchCustomers,
    updateCustomer,
    fetchCustomersReport,
    deleteCustomer,
    handleSortTable,
  }
}
